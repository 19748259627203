/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  const { title = '', author = '' } = data?.site?.siteMetadata || {};

  return (
    <>
      <div id="wrap">
        <Header siteTitle={title} />
        <main className="max-w-screen-lg ml-auto mr-auto mt-0 mb-0 pl-2 pr-2 pb-2">
          {children}
        </main>
        <footer className="max-w-screen-lg ml-auto mr-auto mt-0 mb-0 pl-2 pr-2 text-sm">
          © {author} {new Date().getFullYear()} - Built with
          {` `}
          <a
            href="https://www.gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline text-blue-500"
          >
            Gatsby
          </a>
          {` `}and hosted on{` `}
          <a
            href="https://www.netlify.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline text-blue-500"
          >
            Netlify
          </a>
          .
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
