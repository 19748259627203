import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Code from '../shape-icons/code';
import Menu from '../shape-icons/menu';

const Header = ({ siteTitle }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const expandedClass = expanded ? 'block' : 'hidden';
  return (
    <header className="mb-2">
      <nav className="flex items-center justify-between flex-wrap pr-2 pl-2 pt-6 pb-4 mb-2">
        <div className="flex items-center flex-shrink-0 mr-6">
          <Code className="mr-2" />
          <Link
            to="/"
            className="font-semibold text-xl tracking-tight hover:underline"
          >
            {siteTitle}
          </Link>
        </div>
        <div className="block lg:hidden">
          <button className="flex items-center" onClick={toggleExpanded}>
            <Menu />
          </button>
        </div>
        <div
          className={`flex w-full flex-grow lg:justify-end lg:flex lg:items-center lg:w-auto ${expandedClass}`}
        >
          <div className="text-sm">
            <Link
              to="/series"
              className="block mt-4 lg:inline-block lg:mt-0 mr-4 text-blue-500 hover:underline"
            >
              Series
            </Link>
            <Link
              to="/categories"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-500 hover:underline"
            >
              Categories
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
